/* Keyframes for animations */
@keyframes wave {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(20deg); }
  50% { transform: rotate(-20deg); }
  75% { transform: rotate(20deg); }
  100% { transform: rotate(0deg); }
}

@keyframes pulsate {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

/* General styles */
h1, h2 {
  border-radius: 30px;
  padding: 10px;
  margin: 0 auto;
}

.App {
  background-color: #a8ad77;
}

.about-me-container {
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
  padding: 50px;
}

/* Buttons */
.button-highlight {
  display: inline-flex;
  align-items: center;
  background-color: #d7dda2;
  border-radius: 30px;
  font-family: 'Brandon Grotesque' !important;
  padding: 10px 20px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.1s ease;
}

.button-highlight:hover {
  border-color: #642c1e;
  border-width: 2px;
  border-style: solid;
}

.button-highlight:hover .waving-hand-icon {
  animation: wave 2s infinite;
}

.button-highlight:hover .linkedIn-icon {
  animation: pulsate 3s infinite;
}

.donation-container {
  grid-row: 4; 
  display: flex;
  justify-content: center;
}

.donation-icon {
  font-size: medium;
  padding: 10px;
}

.icon-container {
  grid-row: 3; 
  display: flex;
  gap: 10px;
  justify-content: center; 
}

.linkedIn-icon,
.waving-hand-icon,
.donation-icon {
  font-size: medium;
  padding: 10px;
}

.MuiTouchRipple-root,
.MuiButton-colorPrimary {
  display: none !important;
  color: #642c1e !important;
}

.MuiButtonBase-root {
  display: inline-flex !important;
  background-color: #d7dda2 !important;
  border-radius: 50px !important;
  width: 50% !important;
}

.name-header {
  font-family: 'Palatino Linotype';
  grid-row: 1;
  grid-column: 1;
  text-align: center;
  font-size: 70px;
  color: white;
  background-color: #642c1e;
  width: 800px;
}

.role-description {
  grid-row: 2;
  color: #642c1e;
  text-align: center;
  font-size: x-large;
  font-family: 'Brandon Grotesque';
  width: 80%;
  padding: 30px;
  margin: auto;
  border-radius: 30px;
  background-color: #ceac91;
}

/* Responsive Design */

/* Laptop screens (max-width: 1600px) */
@media (max-width: 1600px) {
  .name-header {
    font-size: 50px;
    width: 600px;
  }

  .role-description {
    font-size: 25px;
    width: 75%;
  }

  .about-me-container {
    padding: 40px;
    gap: 40px;
  }
}

/* Large Screens (max-width: 1200px) */
@media (max-width: 1200px) {
  .name-header {
    font-size: 60px;
    width: 700px;
  }

  .role-description {
    font-size: 25px;
    width: 70%;
  }
}

/* Medium Screens (max-width: 992px) */
@media (max-width: 992px) {
  .name-header {
    font-size: 50px;
    width: 600px;
  }

  .role-description {
    font-size: 20px;
    width: 60%;
  }

  .about-me-container {
    padding: 30px;
    gap: 30px;
  }
}

/* Small Screens (max-width: 768px) */
@media (max-width: 768px) {
  .name-header {
    font-size: 40px;
    width: 500px;
  }

  .role-description {
    font-size: 18px;
    width: 80%;
  }

  .button-highlight,
  .button-wave {
    padding: 8px 16px;
    font-size: 14px;
  }

  .MuiButtonBase-root {
    width: 70% !important;
  }
}

/* Extra Small Screens (max-width: 576px) */
@media (max-width: 576px) {
  .name-header {
    font-size: 30px;
    width: 100%;
  }

  .role-description {
    font-size: 16px;
    width: 100%;
    padding: 20px;
  }

  .about-me-container {
    padding: 20px;
    gap: 20px;
  }

  .button-highlight,
  .button-wave {
    padding: 6px 12px;
    font-size: 12px;
  }

  .MuiButtonBase-root {
    width: 90% !important;
  }
}
